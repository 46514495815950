<template>
  <UpgradeToAllAccessCard
    :benefits-text="benefitsText"
    :header-text="headerText"
    :is-guest="isGuest"
    :in-app-view="inAppView"
    :return-to="returnTo"
    :show-benefits="showBenefits"
    :source="source"
    :small-padding="smallPadding"
    :upgrade-reasons="upgradeReasons"
  />
</template>

<script>
import { mapState } from 'pinia';
import { useMetaStore } from '~/stores/Meta';
import { useUserStore } from '~/stores/User';
import AppViewMixin from '~/utils/AppViewMixin';

export default {
  name: 'UpgradeToAllAccess',

  mixins: [AppViewMixin],

  props: {
    benefitsText: {
      type: String,
      default: 'Upgrade to All-Access and receive exclusive benefits:',
    },
    headerText: {
      type: String,
      default: 'Want to see more?',
    },
    showBenefits: {
      type: Boolean,
      default: true,
    },
    smallPadding: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(useUserStore, ['isGuest']),
    ...mapState(useMetaStore, { upgradeReasons: (store) => store?.pricing?.upgrade_reasons || [] }),

    returnTo() {
      return this.$route.fullPath;
    },

    source() {
      return this.$route.name ? String(this.$route.name).replace('-slug', '') : 'unknown-source';
    },
  },
};
</script>
